import { FeatureFlagEnum } from '../../constants/feature-flag.constants';
import { OfflineFirstFlagValues } from '../types/types';

export enum SideNavMenuOptions {
  SwitchHidden = 'switch-hidden',
  SwitchShown = 'switch-shown',
  NewNavOnly = 'new-nav-shown',
}

export interface FeatureFlags {
  [FeatureFlagEnum.PurchaseOrderRevamp]: boolean;
  [FeatureFlagEnum.MultiTemplateEnabled]: boolean;
  [FeatureFlagEnum.MultiTemplateLogReceipts]: boolean;
  [FeatureFlagEnum.WebSocketPingPongEnabled]: boolean;
  [FeatureFlagEnum.InventoryAPIV3]: boolean;
  [FeatureFlagEnum.PurchaseOrderRevampAdditionalCost]: boolean;
  [FeatureFlagEnum.PurchaseOrderRevampDeliveryDate]: boolean;
  [FeatureFlagEnum.PurchaseOrderRevampDiscount]: boolean;
  [FeatureFlagEnum.ForceOffline]: boolean;
  [FeatureFlagEnum.PurchaseOrderRevampImportProduct]: boolean;
  [FeatureFlagEnum.PurchaseOrderAddSimpleProduct]: boolean;
  [FeatureFlagEnum.CustomFields]: boolean;
  [FeatureFlagEnum.CustomFieldsPackages]: {
    packageName: string;
    count: number;
  };
  [FeatureFlagEnum.RegisterReport]: boolean;
  [FeatureFlagEnum.PublishProducts]: boolean;
  [FeatureFlagEnum.ZatcaEnabled]: boolean;
  [FeatureFlagEnum.ReferralProgram]: boolean;
  [FeatureFlagEnum.OfflineInventorySyncInterval]: number;
  [FeatureFlagEnum.SoftPosEnabled]: boolean;
  [FeatureFlagEnum.SubscriptionPlanCustomAttributes]: boolean;
  [FeatureFlagEnum.OfflineFirstEnabled]: boolean;
  [FeatureFlagEnum.SubscriptionsV2]: boolean;
  [FeatureFlagEnum.SmsOtp]: boolean;
  [FeatureFlagEnum.IssueDatePurchaseOrderRevampV2]: boolean;
  [FeatureFlagEnum.CustomFieldsMetabaseReports]: boolean;
  [FeatureFlagEnum.AdditionalCostTax]: boolean;
  [FeatureFlagEnum.PosUiWebUIChanges]: {
    productInvoiceDiscountTooltip: boolean;
    assignCustomerOnCart: boolean;
    addNewCustomerDialogV2: boolean;
    categoryBarV2: boolean;
    saleItemsSearchV2: boolean;
    addProduct: boolean;
    paymentDialogV2: boolean;
    assignProductToQuickViewCategory: boolean;
    registerListV2: boolean;
    returnInvoicesScreenV2: boolean;
    createQuotationV2: boolean;
    editProductFromSellScreen: boolean;
  };
  [FeatureFlagEnum.OfflineFirst]: OfflineFirstFlagValues;
  [FeatureFlagEnum.PromotionRevamp]: boolean;
  [FeatureFlagEnum.SupplierOpeningBalance]: boolean;
  [FeatureFlagEnum.Marketing]: boolean;
  [FeatureFlagEnum.RecurringExpense]: boolean;
  [FeatureFlagEnum.AccountingOpeningBalance]: boolean;
  [FeatureFlagEnum.StcManagerCustomerId]: string;
  [FeatureFlagEnum.DashboardDateRange]: boolean;
  [FeatureFlagEnum.CoBrowsingEnabled]: boolean;
  [FeatureFlagEnum.PosReborn]: boolean;
  [FeatureFlagEnum.PurchaseOrderRevampV2]: boolean;
  [FeatureFlagEnum.StockCountRevamp]: boolean;
  [FeatureFlagEnum.StockControlRevamp]: boolean;
  [FeatureFlagEnum.TransferStockV2]: boolean;
  [FeatureFlagEnum.IntegrationsV2]: boolean;
  [FeatureFlagEnum.IntegrationsV2Orders]: boolean;
  [FeatureFlagEnum.PermissionsV2]: boolean;
  [FeatureFlagEnum.ProductListingV4]: boolean;
  [FeatureFlagEnum.CashManagementV2]: boolean;
  [FeatureFlagEnum.StockControlReportCubeJs]: boolean;
  [FeatureFlagEnum.TaxesReportCubeJs]: boolean;
  [FeatureFlagEnum.DatatableV3ProductsList]: boolean;
  [FeatureFlagEnum.InventoryReportCubeJs]: boolean;
  [FeatureFlagEnum.B2BNewFlow]: boolean;
  [FeatureFlagEnum.AccountingActivityService]: boolean;
  [FeatureFlagEnum.RewaaOnboarding]: boolean;
  [FeatureFlagEnum.CustomFieldChargeBeeEnabled]: boolean;
  [FeatureFlagEnum.PermissionsUserCreationV2]: boolean;
  [FeatureFlagEnum.OfflineInvoiceSyncRevamp]: boolean;
  [FeatureFlagEnum.POSDiscountUxV2]: boolean;
  [FeatureFlagEnum.PosReceiptCurrency]: string;
  [FeatureFlagEnum.DoomsDaySubscriptionV2]: boolean;
  [FeatureFlagEnum.CustomerReceiveDebitDesignRevamp]: boolean;
  [FeatureFlagEnum.ReportsV2]: boolean;
  [FeatureFlagEnum.OfflineInvoicesCountPollingInterval]: number;
  [FeatureFlagEnum.RewaaPayEnabled]: boolean;
  [FeatureFlagEnum.MinimalistInvoiceDesign]: boolean;
  [FeatureFlagEnum.StcMangerInvoicing]: boolean;
  [FeatureFlagEnum.CustomLoader]: boolean;
  [FeatureFlagEnum.OpeningBalanceSetup]: boolean;
  [FeatureFlagEnum.LoadingSkeleton]: boolean;
  [FeatureFlagEnum.WarehouseCubeReports]: boolean;
  [FeatureFlagEnum.SalesCubeReports]: boolean;
  [FeatureFlagEnum.ReportsV3]: {
    salesByLocation: boolean;
    salesByPaymentStatus: boolean;
    salesByPaymentMethod: boolean;
    salesByTimePeriod: boolean;
    salesByProduct: boolean;
    salesByChannel: boolean;
    salesByCustomer: boolean;
    salesByUser: boolean;
    salesByInvoice: boolean;
    salesByCategory: boolean;
    customerProducts: boolean;
    customerMovement: boolean;
    customerDebitLifetime: boolean;
    locationMovement: boolean;
  };
  [FeatureFlagEnum.EnigmaCubeReports]: boolean;
  [FeatureFlagEnum.RestrictFeatureUsage]: {
    receiveDebit?: boolean;
    return?: boolean;
    sell?: boolean;
    cashManagement?: boolean;
  };
  [FeatureFlagEnum.OcrService]: boolean;
  [FeatureFlagEnum.NewPurchaseOrderInAmountCollected]: boolean;
  [FeatureFlagEnum.ProductMovementReportV2]: boolean;
  [FeatureFlagEnum.TobaccoTaxEnabled]: boolean;
  [FeatureFlagEnum.SearchByCustomer]: boolean;
  [FeatureFlagEnum.ZatcaQRCodeEnabled]: boolean;
  [FeatureFlagEnum.JeSearchHighLights]: boolean;
  [FeatureFlagEnum.ShowTaxDeclarationOtherTax]: boolean;
  [FeatureFlagEnum.ExpenseV2]: boolean;
  [FeatureFlagEnum.NucleusPermissions]: boolean;
  [FeatureFlagEnum.PaidAppFix]: boolean;
  [FeatureFlagEnum.AccountingFiscalPeriodProfitLoss]: boolean;
  [FeatureFlagEnum.NewSideNavMenuComp]: SideNavMenuOptions;
  [FeatureFlagEnum.ImportProductsBeta]: boolean;
  [FeatureFlagEnum.TaxDeclarationSalesEnigma]: boolean;
  [FeatureFlagEnum.AccountTransactionsHtmlExport]: boolean;
  [FeatureFlagEnum.IndepDashboard]: boolean;
  [FeatureFlagEnum.HRModule]: boolean;
  [FeatureFlagEnum.HrModuleSubscription]: boolean;
  [FeatureFlagEnum.ReceiveDebitRevamp]: boolean;
  [FeatureFlagEnum.AccountingIncomeStatementLocation]: boolean;
  [FeatureFlagEnum.PosUnstableInternetFallback]: {
    enable: boolean;
    maxResponseTime: number;
    offlineFirstDuration: number;
  };
  [FeatureFlagEnum.BillingInfoZatcaStatus]: boolean;
  [FeatureFlagEnum.ZatcaConnectFormV2]: boolean;
  [FeatureFlagEnum.CustomerAccountStatementV2]: boolean;
  [FeatureFlagEnum.EnableSubscribedUserOnboarding]: boolean;
  [FeatureFlagEnum.EnableFreeTrialUserOnboarding]: boolean;
  [FeatureFlagEnum.JeSearchRefId]: boolean;
  [FeatureFlagEnum.OnlineOrderErrorBanner]: boolean;
  [FeatureFlagEnum.WooCommerceInstallationBlock]: boolean;
}
