export class Supplier {
  public id?: number;

  public code?: string;

  public name?: string;

  public email?: string;

  public phoneNumber?: string;

  public vatNumber?: string;

  public streetAddress?: string;

  public suburb?: string;

  public city?: string;

  public state?: string;

  public zipCode?: string;

  public countryKey?: string;

  public totalPaidAmount?: number;

  public debitAmount?: number;

  public creditAmount?: number;

  public createdAt?: Date;

  public dueDate?: Date | string;

  public netDue?: number;

  public buildingNumber?: string;

  public district?: string;

  public additionalNumber?: number;

  public openingBalanceAmount?: number;

  public openingBalancePaidAmount?: number;

  public openingBalanceWEFDate?: Date;

  public isActive?: boolean;

  public openingBalance?: {
    amount: number;
    paidAmount: number;
    date: Date;
    amountType: string;
  };

  public isOpeningBalanceEnable?: boolean;

  constructor(supplier?) {
    Object.assign(this, supplier || {});
    if (supplier.openingBalanceAmount !== undefined) {
      this.openingBalance = {
        amount: supplier.openingBalanceAmount,
        paidAmount: supplier.openingBalancePaidAmount,
        date: supplier.openingBalanceWEFDate,
        amountType: 'owed-supplier',
      };
    }
  }

  public get label(): string {
    return this.name;
  }

  public get value(): number {
    return this.id;
  }
}

export interface ISupplierQuery {
  name: string;
  offset: string;
  limit: string;
}
